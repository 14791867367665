import { Fragment, useState, useEffect } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Search({ onSearchChange, apiUrl, apiService, setFilter }) {
  const [query, setQuery] = useState('')
  const [open, setOpen] = useState(true)
  const [items, setItems] = useState([])
  const token = localStorage.getItem('token');

  useEffect(() => {
    const fetchData = async () => {
      try {
        var payload = {}
        if (apiService === 'SearchProducts') {
          payload = {
            "Query": query,
            "IncludedFields": [
              "primary_image",
              "logical_level",
              "backend_id",
              "backend_ids",
              "display_price",
              "product_id",
              "display_value",
              "publication"
            ]
          }
        }
        if (apiService === 'SearchUsers') {
          payload = {
            "SearchQuery": query
          }
        }

        const response = await fetch('https://' + apiUrl + '/message/' + apiService, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'EVA-User-Agent': 'AnalyticsPortal',
            'Authorization': token
          },
          body: JSON.stringify(payload)
        });
        if (response.ok) {
          const data = await response.json();
          if (apiService === 'SearchProducts') {
            setItems(data?.Products)
          }
          if (apiService === 'SearchUsers') {
            setItems(data?.Result?.Page)
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    if (query !== '') {
      fetchData();
    }
  }, [query, apiUrl, apiService, token]);

  const handleSearchClose = (e) => {
    setOpen(false);
    onSearchChange(false);
  };

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as="div" className="relative z-50" onClose={handleSearchClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 transition-opacity bg-slate-900/25 backdrop-blur transition-opacity opacity-100" />
        </Transition.Child>

        <div className="fixed inset-0 z-50 w-screen overflow-y-auto mt-10 p-4 sm:p-6 md:p-20">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="mx-auto max-w-xl transform divide-y divide-zinc-100 dark:divide-zinc-800 overflow-hidden bg-white dark:bg-zinc-900 transition-all rounded-lg shadow-xl ring-1 ring-black dark:ring-white/10 ring-opacity-5">
              <Combobox onChange={(item) => {
                setFilter(apiService === 'SearchProducts' ? item.display_value : apiService === 'SearchUsers' ? item.ID : null)
                handleSearchClose();
              }}>
                <div className="relative">
                  <MagnifyingGlassIcon
                    className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-zinc-400"
                    aria-hidden="true"
                  />
                  <Combobox.Input
                    className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-zinc-900 dark:text-white placeholder:text-zinc-400 focus:ring-0 sm:text-sm outline-none"
                    placeholder="Search..."
                    onChange={(event) => setQuery(event.target.value)}
                  />
                </div>

                {items.length > 0 && (
                  <Combobox.Options static className="max-h-96 transform-gpu scroll-py-3 overflow-y-auto p-3">
                    {items.map((item) => (
                      <Combobox.Option
                        key={apiService === 'SearchProducts' ? item.product_id : apiService === 'SearchUsers' ? item.ID : null}
                        value={item}
                        className={({ active }) =>
                          classNames('flex cursor-default select-none rounded-xl p-3', active && 'bg-zinc-100 dark:bg-zinc-800')
                        }
                      >
                        {({ active }) => (
                          <>
                            <div
                              className={classNames(
                                'flex h-10 w-10 flex-none items-center justify-center rounded-lg'
                              )}
                            >
                              {apiService === 'SearchProducts'
                                ? <img src={item?.primary_image?.url} alt={item.diplay_value} className='object-cover rounded-md' />
                                : apiService === 'SearchUsers'
                                  ? <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-zinc-500"><span className="text-xs font-medium leading-none text-white">{item?.FirstName && item?.LastName ? item.FirstName.charAt(0) + item.LastName.charAt(0) : 'NA'}</span></span>
                                  : null
                              }
                            </div>
                            <div className="ml-4 mr-10 flex-auto">
                              <p
                                className={classNames(
                                  'text-sm font-medium',
                                  active ? 'text-zinc-900 dark:text-white' : 'text-zinc-700 dark:text-zinc-300'
                                )}
                              >
                                {apiService === 'SearchProducts' ? item.display_value : apiService === 'SearchUsers' ? item.FullName : null}
                              </p>
                              <p className={classNames('text-sm', active ? 'text-zinc-700 dark:text-zinc-300' : 'text-zinc-500')}>
                                {apiService === 'SearchProducts' ? item.product_id + " | " + item.backend_id : apiService === 'SearchUsers' ? item.ID + " | " + item.EmailAddress : null}
                              </p>
                              <p className={classNames('text-sm', active ? 'text-zinc-700 dark:text-zinc-300' : 'text-zinc-500')}>
                                {apiService === 'SearchProducts' ? "Logical level - " + item.logical_level : apiService === 'SearchUsers' ? "Type " + item.Type : null}
                                { }
                              </p>
                            </div>
                            <div
                              className={classNames(
                                'flex h-10 w-10 pr-5 sm:pr-7 flex-none items-center justify-center rounded-lg'
                              )}
                            >
                              <span className={`items-center justify-center rounded-md border bg-white dark:bg-zinc-900 py-1 px-2 ml-2 text-xs font-medium max-sm:hidden ${item?.publication?.length > 0 || !item?.IsDeactivated ? 'border-green-600 text-green-600' : 'border-red-600 text-rose-600'}`}>
                                {item?.publication?.length > 0 || !item?.IsDeactivated ? apiService === 'SearchProducts' ? "Published" : apiService === 'SearchUsers' ? "Active" : null : apiService === 'SearchProducts' ? "Not published" : apiService === 'SearchUsers' ? "Inactive" : null}
                              </span>
                            </div>
                          </>
                        )}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && items.length === 0 && (
                  <div className="px-6 py-14 text-center text-sm sm:px-14">
                    <ExclamationCircleIcon
                      type="outline"
                      name="exclamation-circle"
                      className="mx-auto h-6 w-6 text-zinc-400"
                    />
                    <p className="mt-4 font-semibold text-zinc-900">No {apiService === 'SearchProducts' ? "products" : apiService === 'SeachUsers' ? 'users' : null} found</p>
                    <p className="mt-2 text-zinc-500">No {apiService === 'SearchProducts' ? "products" : apiService === 'SeachUsers' ? 'users' : null} found for this search term. Please try again.</p>
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
