import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Notification from '../components/notification'

import { url } from '../api/configs/EvaConfig';
import EvaLogo from '../assets/eva.png';
import KikoLogo from '../assets/kiko.png'
import AjaxLogo from '../assets/ajax.png'
import AmacLogoWhite from '../assets/amac_white.png'

const logos = {
    default: EvaLogo,
    kiko: KikoLogo,
    ajax: AjaxLogo,
    amac: AmacLogoWhite
}

const colors = {
    default: 'to-sky-400',
    kiko: 'to-pink-400',
    ajax: 'to-red-400',
    amac: 'to-yellow-400'
}

export default function SignIn() {
    const [formData, setFormData] = useState({
        Username: '',
        Password: '',
        SelectFirstOrganizationUnit: true
    });
    const { brand } = useParams();

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            if (formData.Username.includes('@in2intel.com') || formData.Username.includes('@newblack.io') || formData.Username.includes('n.zootjes@') || formData.Username.includes('noud@')) {
                const api_url = url[brand] ? url[brand] : url['default']
                const response = await fetch('https://' + api_url + '/message/Login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'EVA-User-Agent': 'AnalyticsPortal'
                    },
                    body: JSON.stringify(formData)
                });

                if (response.ok) {
                    // User successfully logged in
                    // Store session data in localStorage
                    const userData = await response.json();
                    if (userData.Authentication === 2) {
                        localStorage.setItem('token', userData.User.AuthenticationToken);
                        // Redirect to dashboard
                        Notification('success', 'Welcome!', 'Successfully logged in.');
                        if (window.location.pathname.includes('/signin')) {
                            window.location.href = '/dashboard';
                        } 
                        else {
                            window.location.reload();
                        }
                    }
                    else {
                        Notification('error', 'Invalid credentials', 'Wrong username or password.');
                    }
                } else {
                    Notification('error', 'Something wrent wrong!', 'Please try again or contact support');
                }
            }
            else {
                Notification('info', 'No access', 'You are not allowed to view this content');
            }
        } catch (error) {
            console.log(error);
            Notification('error', 'Something wrent wrong!', 'Please try again or contact support');
        }
    };

    return (
        <>
            <div className='relative h-[100dvh] sm:h-screen w-screen overflow-y-hidden'>
                <div className="flex min-h-screen flex-col justify-center px-6 py-12 lg:px-8">
                    <div className="absolute inset-0 bg-gradient-to-b from-black from-60% to-zinc-700 to-100% sm:to-100% -z-20"></div>
                    <div className="absolute inset-0 animate-gradient-xy bg-gradient-to-b from-black from-60% to-sky-400 to-100% sm:to-100% opacity-30 -z-10"></div>
                    <div className={`absolute inset-0 animate-gradient-xy bg-gradient-to-b from-black from-60% ${colors[brand]} to-100% sm:to-100% opacity-30 -z-10`}></div>
                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                        <img
                            src={logos[brand] ? logos[brand] : logos['default']}
                            alt="EVA"
                            className={`mx-auto w-20 h-auto ${logos[brand] && brand !== 'amac' ? 'invert' : ''}`}
                        />
                        <h2 className="mt-10 text-center text-2xl font-extrabold leading-9 tracking-tight font-heading text-gray-100">
                            Sign in to your {brand ? 'EVA' : 'GUC'} account
                        </h2>
                    </div>

                    <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                        <form className="space-y-6" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="Username" className="block text-sm font-medium leading-6 text-gray-100">
                                    Username
                                </label>
                                <div className="mt-2">
                                    <input
                                        id="Username"
                                        name="Username"
                                        type="username"
                                        autoComplete="email"
                                        required
                                        value={formData.Username}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 p-1.5 bg-zinc-800 text-gray-100 shadow-sm ring-1 ring-inset ring-zinc-700 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center justify-between">
                                    <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-100">
                                        Password
                                    </label>
                                    <div className="text-sm">
                                        <a href="mailto:max@newblack.io" className="font-semibold text-zinc-500 hover:text-zinc-200">
                                            Forgot password?
                                        </a>
                                    </div>
                                </div>
                                <div className="mt-2">
                                    <input
                                        id="Password"
                                        name="Password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        value={formData.Password}
                                        onChange={handleChange}
                                        className="block w-full rounded-md border-0 p-1.5 bg-zinc-800 text-gray-100 shadow-sm ring-1 ring-inset ring-zinc-700 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-zinc-400 sm:text-sm sm:leading-6"
                                    />
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    className="flex w-full justify-center rounded-md bg-white px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm hover:bg-zinc-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                                >
                                    Sign in
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}
